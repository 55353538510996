<template>

	<!-- content -->
	<main class="container">
		<div class="content">
			<div class="content_body">
				<div class="inner">
					<div class="error_info">
						<h2 class="tit">페이지를 찾을 수 없습니다.</h2>
						<p class="desc">
							방문하시려는 페이지의 주소가 잘못 입력되었거나, <br>
							페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.<br>
							입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
						</p>
					</div>
					<div class="button_area">
						<button type="submit" class="btn_primary" @click="goHome">메인으로 돌아가기</button>
					</div>
				</div>
			</div>
		</div>
	</main>
	<!--//content -->

</template>

<script>
export default {
	name: "error",
	methods: {
		goHome () {
			location.href="/"
		}
	}
}
</script>

<style scoped>

</style>